/**
 * Componente Home que permite renderizar la pantalla inicial de la aplicación
 */

import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Layout, Menu, Button, Tooltip, Space } from 'antd';
import { UserOutlined, LogoutOutlined, AppstoreOutlined, SearchOutlined, SolutionOutlined, TagsOutlined, FolderOutlined, AuditOutlined, QuestionCircleFilled } from '@ant-design/icons';
import _ from 'lodash';

import '../styles/home.scss';
/**
 * 


import project from '../../../../package';

const { name, version } = project;
 */
import { logout } from '../../actions/auth/logout';
import { Documents } from '../documents';
import { Users } from '../users';
import { Customers } from '../customers';
import { References } from '../references';
import { Folders } from '../folders';
import { Search } from '../search';
import { Audit } from '../audit';
import '../styles/home.scss';
import '../styles/acces.scss';
const { Header, Content, Footer } = Layout;

const LogoCompany = "https://firebasestorage.googleapis.com/v0/b/gestor-documental-67434.appspot.com/o/logo%20cr.png?alt=media&token=47b447f5-7845-4168-87dd-7af7c3644b1d";

/**
 * Exporta el componente Home
 */
export const Home = () => {

  // Funcion que permite ejecutar todas las acciones del estado de redux
  const dispatch = useDispatch();
  // Selecciona los reductores
  const roles = useSelector(state => state.get('roles').get('roles').roles);
  const user = useSelector(state => state.get('authenticate').get('login').user);

  // Manejo de los estados del componente
  const [content, setContent] = useState('documents');

  const componets = {
    documents: <Documents />,
    users: <Users />,
    customers: <Customers />,
    references: <References />,
    folders: <Folders />,
    search: <Search />,
    audit: <Audit />
  };

  /**
   * Metodo que se ejcuta cuando se hace clic en las opciones del menu
   * Se encarga de cargar el componente correspondiente
   * @param {object} evt 
   */
  const onMenuClick = evt => setContent(evt.key);

  /**
   * Metodo que permite cerrar la sesión de un usuario 
   */
  const onLogout = () => dispatch(logout());

  // Ejecuta la funcion una vez termina de renderizar el componente
  useEffect(() => { if (!roles?.documents) dispatch(logout()); }, [dispatch, roles.documents]);

  /**
   * Metodo que renderiza el componente
   */
  return (
    <Layout className="layout-app" >
      <Header style={{ padding: 0, color: '#1890ff', paddingRight: 20 }} >
        <div style={{ float: 'left', marginRight: 20 }} >
          <Space>
            <Button type="primary" ghost icon={<UserOutlined />} > {user?.displayName} </Button>
            <Button type="primary" ghost icon={<QuestionCircleFilled />}
              onClick={() => window.open('http://crconsultorescolombia.com/manual/Manual-Daysi.html', "_blank")}
            > Manual </Button>
            <img alt="Logo Company" src={LogoCompany} style={{ width: 80, height: 50, marginRight: 20, marginLeft: 20 }} />
          </Space>
        </div>
        <div style={{ float: 'right' }} >
          <span style={{ marginRight: 20, fontFamily: 'Cookie', fontSize: '8vh', color: '#E70810', textAlign: 'center', display: 'line', }}> Daysi </span>
          <Tooltip placement="left" title='Cerrar sesion' >
            <Button style={{ borderColor: '#800000', backgroundColor: '#800000' }}  shape="circle" onClick={onLogout} >
              <LogoutOutlined style={{ fontSize: '20px', backgroundColor: '#800000', color:'#00000' , }} />
            </Button>
          </Tooltip>
        </div>
      </Header>
      <Layout>
        <Menu onClick={onMenuClick} defaultSelectedKeys={['documents']} mode="horizontal" theme="dark" >
          {roles?.documents?.read ? <Menu.Item key="documents" > <AppstoreOutlined /> Documentos </Menu.Item> : null}
          {roles?.documents?.read ? <Menu.Item key="search" > <SearchOutlined /> Consultas </Menu.Item> : null}
          {roles?.users?.read ? <Menu.Item key="users" > <UserOutlined /> Usuarios </Menu.Item> : null}
          {roles?.customers?.read ? <Menu.Item key="customers" > <SolutionOutlined /> Clientes </Menu.Item> : null}
          {roles?.references?.read ? <Menu.Item key="references" > <TagsOutlined /> Referencias </Menu.Item> : null}
          {roles?.folders?.templates?.read ? <Menu.Item key="folders" > <FolderOutlined /> Carpetas </Menu.Item> : null}
          {roles?.documents?.read && roles?.documents?.level === "all" ? <Menu.Item key="audit" > <AuditOutlined /> Log de auditoria </Menu.Item> : null}
        </Menu>
        <Content style={{ margin: '24px 16px 0' }} >
          <div className="amarillo" style={{ padding: 24, background: '#fff', minHeight: 360 }} > {componets[content]} </div>
          <Footer style={{ textAlign: 'center' }} >
            {` Versión `} - Para soporte escribir a:
            <br />
            Email:
            <a href={`mailto:rreyes@crconsultorescolombia.com?Subject=Cr Consultores`} target="_top" >
              {` rreyes@crconsultorescolombia.com`}
            </a> - Ramiro Reyes Amaya.
          </Footer>
          {/* <Footer   style = {{ textAlign: 'center' }} >
              ©2018 Created by <a href = { homepage } target = "_blank" > { author.name } </a> - { `${ _.capitalize( name ) } Versión ${ version }` }
              <br />
              Email:  
                <a href = { `mailto:${ author.email }?Subject=Contacto` } target = "_top" >
                  { ` ${ author.email } ` }
                </a>
            </Footer> */}
        </Content>
      </Layout>
    </Layout>
  );
};